import { Link } from "react-router-dom";
import * as Yup from "yup";
import useToggle from "../../hooks/core/useToggle";
import { Form, Formik, FormikValues } from "formik";
import User from "../../models/User";
import Input from "../UI/Input";
import Button from "../UI/Button";

type PropsType = {
  singupUser: (userDetails: any) => void;
};

const SignupForm = ({ singupUser }: PropsType) => {
  const [toggleSubmit, setToggleSubmit] = useToggle(false);

  const initialValues: any = {
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    password: "",
    confirmPassword: "",
  };

  // Formik - Example
  // https://medium.com/@kapuriya1jasmin/form-validation-in-react-with-formik-and-yup-with-typescript-f482686a2982
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    username: Yup.string()
      .required("Username is required")
      .min(8, "Username must be 8 to 15 characters long")
      .max(15, "Username must be 8 to 15 characters long"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Password must contain at lest 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
  });

  const onFormSubmit = async (
    values: FormikValues,
    { setSubmitting, resetForm }: any
  ) => {
    setToggleSubmit(true); // disable the button & update name
    const userDetails: User = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      username: values.username,
      password: values.password,
    };

    const isSuccess = await singupUser(userDetails); //Return the data to home page for saving
    if (isSuccess!) {
      resetForm(initialValues); //reset form after success
    }
    setSubmitting(false); //Formik
    setToggleSubmit(false); // enable the button & update button name
  };

  return (
    <div className="w-full max-w-sm text-left p-4 sm:p-6 md:p-8 bg-white border border-gray-200 rounded-lg shadow">
      <h5 className="mb-4 text-xl font-medium text-center">Signup!</h5>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onFormSubmit}
      >
        <Form method="post">
          <div className="flex flex-col space-y-2 sm:space-y-4">
            <h5 className="mb-2 text-base font-semibold underline text-left">
              User Information
            </h5>
            <Input
              id="firstName"
              controlName="firstName"
              fieldName="First Name"
            />
            <Input id="lastName" controlName="lastName" fieldName="Last Name" />
            <Input id="email" controlName="email" fieldName="Email" />
            <h5 className="mb-2 text-base font-semibold underline text-left">
              Login Credentials:
            </h5>
            <Input id="username" controlName="username" fieldName="Username" />
            <Input
              id="password"
              controlName="password"
              fieldName="Password"
              type="password"
            />
            <Input
              id="confirmPassword"
              controlName="confirmPassword"
              fieldName="Confirm Password"
              type="password"
            />
          </div>
          <div className="pt-4">
            <Button
              type="submit"
              className=" w-full"
              text="Signup"
              submittingText="Registering"
              isSubmitting={toggleSubmit}
            />
            
            <div className="mt-4 text-sm font-medium text-gray-500">
              Already registered?
              <Link
                to="/auth/login"
                className="ml-2 text-blue-700 hover:underline"
              >
                Login!
              </Link>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default SignupForm;
