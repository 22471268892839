import PageContentLayout from "../../components/Layout/PageContent";
import FeedbackForm from "../../components/Core/FeedbackForm";
import UserFeedback from "../../models/core/Feedback";
import { submitFeedback } from "../../apis/feedbackAPI";
import { useContext } from "react";
import { AlertsContext } from "../../state/AlertContext";
import { AlertTypes } from "../../models/core/Alert";

const FeedbackPage: React.FC<{}> = (props) => {
  const alertsContext = useContext(AlertsContext);

  const onSubmit = async (feedback: UserFeedback) => {
    if (
      feedback === undefined ||
      feedback?.name === undefined ||
      feedback?.email === undefined ||
      feedback?.message === undefined
    ) {
      alertsContext.addAlert(
        AlertTypes.Error,
        "Please enter the feedback details."
      );
      return false;
    }

    try {
      //fetch for creating new game
      const response = await submitFeedback(feedback);

      //throw error
      if (!response.ok) {
        alertsContext.addAlert(
          AlertTypes.Error,
          "Could not submit feedback. Please try again later!: " +
            response.statusText
        );
        return false;
      } else {
        alertsContext.addAlert(
          AlertTypes.Success,
          "Feedback Submitted successfully"
        );
        return true;
      }
    } catch (exception) {
      alertsContext.addAlert(
        AlertTypes.Error,
        "Unhandled exception occured. Try again later! : " + exception
      );
      return false;
    }
  };

  return (
    <>
      <PageContentLayout title="">
        <div className="flex justify-center">
          <FeedbackForm onSubmitFeedback={onSubmit} />
        </div>
      </PageContentLayout>
    </>
  );
};

export default FeedbackPage;
