import { useState } from "react";

export default function useTabIndex(defaultValue: number) {
  const [value, setValue] = useState(defaultValue);

  function changeTaxIndex(index: number) {
    setValue(index);
  }

  return [value, changeTaxIndex] as const;
}
