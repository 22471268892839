import { NavLink } from "react-router-dom";

const FooterLayout: React.FC<{}> = (props) => {
  return (
    <>
      <footer className="hidden md:block w-full border-t border-gray-200 bg-gray-50 shadow">
        <div className="max-w-screen-lg mx-auto flex items-center justify-center py-4 px-8">
          <ul className="flex space-x-8 items-center text-sm font-medium text-gray-500">
            <li>
              Made with 💚 by &nbsp;
              <a
                href="https://praveenanuraj.com/"
                className="hover:underline font-bold"
                target="_blank"
              >
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-red-500 to-orange-300">
                  Praveen
                </span>
              </a>
            </li>
            <li>
              <a
                href="https://www.buymeacoffee.com/praveenanuraj"
                className="hover:underline me-4 md:me-6"
                target="_blank"
              >
                Support Me
              </a>
            </li>
            <li>
              <NavLink to="/feedback" className="hover:underline me-4 md:me-6">
                Submit Feedback
              </NavLink>
            </li>
            <li>
              <a
                href="https://twitter.com/PraveenAnuraj"
                className="hover:underline"
                target="_blank"
              >
                Reach Me
              </a>
            </li>
          </ul>
        </div>
      </footer>
      <footer className="md:hidden w-full border-t border-gray-200 bg-gray-50 shadow">
        <div className="mx-auto flex flex-col items-center justify-center space-y-4 p-4">
          <span className="text-sm text-gray-500">
            Made with 💚 by &nbsp;
            <a
              href="https://praveenanuraj.com/"
              className="hover:underline font-bold"
              target="_blank"
            >
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-red-500 to-orange-300">
                Praveen
              </span>
            </a>
          </span>
          <ul className="flex items-center text-sm font-medium text-gray-500">
            <li>
              <a
                href="https://www.buymeacoffee.com/praveenanuraj"
                className="hover:underline me-4 md:me-6"
                target="_blank"
              >
                Support Me
              </a>
            </li>
            <li>
              <NavLink to="/feedback" className="hover:underline me-4 md:me-6">
                Feedback
              </NavLink>
            </li>
            <li>
              <a
                href="https://twitter.com/PraveenAnuraj"
                className="hover:underline"
                target="_blank"
              >
                Reach Me
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
};

export default FooterLayout;
