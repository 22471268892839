import { ErrorMessage, Field } from "formik";
import ReferenceCodeValue from "../../models/core/ReferenceCodeValue";

const Input = ({
  id,
  controlName,
  fieldName,
  className = "",
  isRequired = true,
  inputType = "input",
  options = [],
  ...props
}: any) => {
  const inputStyles =
    "w-full rounded border border-stroke bg-transparent my-1 py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter " +
    className;

  return (
    <div>
      <label htmlFor={controlName} className="">
        {isRequired && <span className="text-red-600 font-bold"> * </span>}{" "}
        {fieldName}
      </label>
      {inputType !== "select" && (
        <Field
          id={controlName}
          name={controlName}
          className={inputStyles}
          autoComplete="on"
          {...props}
        />
      )}
      {inputType === "select" && (
        <>
          <Field
            id={controlName}
            name={controlName}
            className="w-full rounded border border-stroke bg-transparent my-1 py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter "
            autoComplete="on"
            component="select"
            {...props}
          >
            <option value="">--</option>
            {options.map((x: ReferenceCodeValue) => {
              return (
                <option value={x.codeValue} key={x.codeValue}>
                  {x.description}
                </option>
              );
            })}
          </Field>
        </>
      )}

      <ErrorMessage
        name={controlName}
        component="div"
        className="text-sm text-red-800"
      />
    </div>
  );
};

export default Input;
