import { Link } from "react-router-dom";
import Game from "../../models/Game";
import { getGamePlayerScoreSummary } from "../../utils/gamesUtil";
import PlayerWinning from "../GameBoard/Player/PlayerWinning";

const GameItem: React.FC<{ game: Game }> = (props) => {
  const scores: any[] = getGamePlayerScoreSummary(props.game);

  return (
    <div className="w-full max-w-sm  flex flex-col p-4 text-left bg-white border border-gray-200 rounded-lg shadow">
      <div className="flex flex-col items-center space-y-2">
        <p className="text-lg font-bold text-gray-900 truncate ">
          {props.game.name}
        </p>
        <p className="text-sm text-gray-500 truncate dark:text-gray-400">
          {props.game.resultValue === "INPG" ? "Game In Progress" : "Game Over"}
        </p>
        <div className="inline-flex my-4 sm:my-0 items-center text-base font-semibold text-gray-900">
          <Link
            className="inline-flex rounded bg-gray-800 py-1 px-3 font-medium text-white hover:bg-opacity-90 sm:py-2.5 sm:px-6"
            to={`${props.game.gameId}`}
          >
            View Scoreboard
          </Link>
        </div>
      </div>

      <PlayerWinning game={props.game} />

      <div>
        <ul className="divide-y divide-gray-200 px-8">
          {scores &&
            scores.map((x: any, index: number) => {
              return (
                <li className="py-2" key={index}>
                  <div className="flex items-center">
                    <div className="flex-1 min-w-0 ms-4">
                      <p className="text-sm font-medium text-gray-900 truncate">
                        {x.name}
                      </p>
                    </div>
                    <div className="inline-flex items-center text-base font-semibold text-gray-900 ">
                      {x.score}
                    </div>
                  </div>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default GameItem;
