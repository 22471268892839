import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

type PropsType = {
  children: React.ReactNode;
  title: string;
  open: boolean;
  className: string;
  onClose: () => void;
};

const Modal = ({
  children,
  title,
  open,
  className = "",
  onClose,
}: PropsType) => {
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    const modal = dialogRef.current;

    if (modal === undefined) {
      return;
    }
    if (open) {
      modal!.showModal();
    }

    return () => modal!.close();
  }, [open]);




  return createPortal(
    <dialog
      ref={dialogRef}
      className={`overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-fit h-fit  md:inset-0 ${className}`}
      onClose={onClose}
    >
      {/*  Modal content */}
      <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
        {/* Modal header */}
        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
            {title}
          </h3>
        </div>
        {/* Modal body */}
        <div className="p-4 md:p-5">{children}</div>
      </div>
    </dialog>,
    document.getElementById("modal")!
  );
};

export default Modal;
