import { GC_API_URL } from "../constants/constants";
import { getAuthToken } from "../utils/auth";
import { getGamePasscode } from "../utils/gamesUtil";

export async function getGames() {
  const response = await fetch(
    GC_API_URL + "games/history?pageNumber=1&pageSize=8",
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + getAuthToken(),
      },
    }
  );
  return response;
}

export async function searchGames(parameters: any) {
  const response = await fetch(GC_API_URL + "games/search", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getAuthToken(),
    },
    body: JSON.stringify(parameters),
  });
  return response;
}

export async function getGame(id: number) {
  const response = await fetch(GC_API_URL + "games/" + id, {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + getAuthToken(),
    },
  });
  return await response;

  // if (!response.ok) {
  //   throw json(
  //     { message: "Could not fetch game data for id : " + id },
  //     { status: 500 }
  //   );
  // } else {
  //   const resData = await response.json();
  //   return resData;
  // }
}

export async function createGame(game: any) {
  const response = await fetch(GC_API_URL + "games", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getAuthToken(),
    },
    body: JSON.stringify(game),
  });
  return response;
}

export async function updateGame(id: number, game: any) {
  const response = await fetch(GC_API_URL + "games/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getAuthToken(),
    },
    body: JSON.stringify(game),
  });
  return response;
}

export async function deleteGame(id: number) {
  const response = await fetch(GC_API_URL + "games/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getAuthToken(),
    },
  });
  return response;
}

export async function associateGameToUser(id: number) {
  const response = await fetch(GC_API_URL + `games/${id}/associate-user`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getAuthToken(),
    },
  });
  return response;
}

export async function getGameBoard(id: number) {
  const response = await fetch(
    GC_API_URL + `games/${id}/game-board?gamePasscode=${getGamePasscode()}`,
    {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + getAuthToken(),
      },
    }
  );
  return await response;
}

export async function getPlayerSummary(id: number) {
  const response = await fetch(
    GC_API_URL + `games/${id}/player-summary?gamePasscode=${getGamePasscode()}`,
    {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + getAuthToken(),
      },
    }
  );
  return await response;
}
