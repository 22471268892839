import { Outlet } from "react-router-dom";
import HeaderLayout from "../../components/Layout/Header";
import Alerts from "../../components/UI/Alert";
import { AlertsContextProvider } from "../../state/AlertContext";
import FooterLayout from "../../components/Layout/Footer";

const RootLayout: React.FC<{}> = (props) => {
  return (
    <>
      <HeaderLayout></HeaderLayout>
      <AlertsContextProvider>
        <Alerts />
        <Outlet />
      </AlertsContextProvider>
      <FooterLayout />
    </>
  );
};

export default RootLayout;
