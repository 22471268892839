
import { Outlet } from "react-router-dom";

const GamesRootPage: React.FC<{}> = (props) => {

    return (
        <>
                <Outlet />
        </>
    )
}

export default GamesRootPage;

