import { createBrowserRouter, Navigate } from "react-router-dom";
import RootLayout from "./pages/Core/Root";
import ErrorPage from "./pages/Error";
import HomePage from "./pages/Home";
import GamesPage, { gamesLoader } from "./pages/Games/Games";
import GamesRootPage from "./pages/Games/GamesRoot";
import SignupPage from "./pages/Auth/Signup";
import NewGamePage from "./pages/Games/NewGame";
import { logoutAction } from "./pages/Auth/Logout";
import { tokenLoader } from "./utils/auth";
import GamesBoardPage from "./pages/Games/GameBoard";
import { BoardContextProvider } from "./state/GameBoardContext";
import LoginPage, { loginAuthAction } from "./pages/Auth/Login";
import FeedbackPage from "./pages/Core/Feedback";


const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    id: "root",
    loader: tokenLoader,
    children: [
      { index: true, element: <HomePage /> },
      { path: "feedback", element: <FeedbackPage />},
      {
        path: "auth",
        children: [
          { index: true, element: <Navigate to="login" replace /> },
          { path: "signup", element: <SignupPage /> },
          { path: "login", element: <LoginPage />, action: loginAuthAction },
          { path: "logout", action: logoutAction },
        ],
      },
      {
        path: "games",
        element: <GamesRootPage />,
        children: [
          { index: true, element: <GamesPage />, loader: gamesLoader },
          {
            path: ":gameId",
            element: (
              <BoardContextProvider>
                <GamesBoardPage />
              </BoardContextProvider>
            ),
          },
          { path: "new", element: <NewGamePage /> },
        ],
      },
    ],
  },
]);

export default appRouter;
