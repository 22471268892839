import * as Yup from "yup";
import Input from "../UI/Input";
import Button from "../UI/Button";
import useToggle from "../../hooks/core/useToggle";
import SetupGameModel from "../../models/GameBoard/SetupGame";
import { Form, Formik, FormikValues } from "formik";

type PropsType = {
  onGameSetup: (gameDetails: any) => void;
};

const GameForm = ({ onGameSetup }: PropsType) => {
  const [toggleSubmit, setToggleSubmit] = useToggle(false);

  const initialValues: SetupGameModel = {
    gameName: "",
    players: "",
    scoreOut: 201,
    scoreDrop: 20,
    scoreMiddleDrop: 40,
    scoreFull: 80,
  };

  const validationSchema = Yup.object().shape({
    gameName: Yup.string().required("Game Name is required"),
    players: Yup.string().required("Players are required"),
    scoreOut: Yup.number().required("Players out at score is required"),
    scoreDrop: Yup.number().required("Drop score is required"),
    scoreMiddleDrop: Yup.number().required("Middle drop score is required"),
    scoreFull: Yup.number().required("Full count score is required"),
  });

  const onFormSubmit = async (
    values: FormikValues,
    { setSubmitting, resetForm }: any
  ) => {
    setToggleSubmit(true); // disable the button & update name
    const game: SetupGameModel = {
      gameName: values.gameName,
      scoreOut: values.scoreOut,
      scoreDrop: values.scoreDrop,
      scoreMiddleDrop: values.scoreMiddleDrop,
      scoreFull: values.scoreFull,
      players: values.players,
    };

    const isSuccess = await onGameSetup(game); //Return the data to the parent page for saving
    if (isSuccess!) {
      resetForm(initialValues); //reset form after success
    }
    setSubmitting(false); //Formik
    setToggleSubmit(false); // enable the button & update button text
  };

  return (
    <div className="w-full max-w-sm text-left p-4 sm:p-6 md:p-8 bg-white border border-gray-200 rounded-lg shadow">
      <h5 className="mb-4 text-xl font-medium text-center">Setup New Game!</h5>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onFormSubmit}
      >
        <Form method="post">
          <div className="flex flex-col space-y-2 sm:space-y-4">
            <h5 className="mb-2 text-base font-semibold underline text-left">
              Game & Player information:
            </h5>
            <Input
              id="gameName"
              controlName="gameName"
              fieldName="Game Name"
              placeholder="e.g. Ravi's Home"
            />
            <Input
              id="players"
              controlName="players"
              fieldName="Players"
              placeholder={`Prakash\nMadhu\nNalan\nLakshmi\nShekar\nNaveen\nNandhu`}
              component="textarea"
              rows="7"
            />
            <h5 className="mb-2 text-base font-semibold underline text-left">
              Score Settings:
            </h5>

            <Input
              id="scoreOut"
              controlName="scoreOut"
              fieldName="Player Out At"
              placeholder="201"
            />
            <Input
              id="scoreDrop"
              controlName="scoreDrop"
              fieldName="Drop Score"
              placeholder="20"
            />
            <Input
              id="scoreMiddleDrop"
              controlName="scoreMiddleDrop"
              fieldName="Middle Drop"
              placeholder="40"
            />
            <Input
              id="scoreFull"
              controlName="scoreFull"
              fieldName="Full Score"
              placeholder="80"
            />

            <div className="pt-4">
              <Button
                className=" w-full"
                type="submit"
                text="Setup Game"
                submittingText="Saving"
                isSubmitting={toggleSubmit}
              />
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default GameForm;
