import { Form, NavLink, useRouteLoaderData } from "react-router-dom";
import { checkIfGameIsInProgress } from "../../utils/gamesUtil";

const MainNavigation: React.FC<{}> = (props) => {
  const linkBaseStyle =
    "group inline-flex items-center justify-center py-2 px-6 text-md font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 hover:underline focus-visible:outline-gray-600";
  const linkActiveStyle =
    "group inline-flex items-center justify-center underline py-2 px-6 text-md font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 active:bg-gray-800 active:text-gray-100 focus-visible:outline-gray-600";
  const token = useRouteLoaderData("root");
  const notLoggedIn: boolean = token === null ? true : false;

  return (
    <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 w-full border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white">
      <li>
        <NavLink
          to="/games"
          className={({ isActive }) =>
            isActive ? linkActiveStyle : linkBaseStyle
          }
        >
          Games
        </NavLink>
      </li>
      {notLoggedIn && (
        <>
          <li>
            <NavLink
              to="/auth/login"
              className={({ isActive }) =>
                isActive ? "hidden" : linkBaseStyle
              }
            >
              Login
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/auth/signup"
              className={({ isActive }) =>
                isActive
                  ? "hidden"
                  : "text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center justify-center"
              }
            >
              Signup
            </NavLink>
          </li>
        </>
      )}

      {!notLoggedIn && (
        <>
          <li>
            <Form action="/auth/logout" method="post">
              <button className={linkBaseStyle}>Logout</button>
            </Form>
          </li>
        </>
      )}
    </ul>
  );
};

export default MainNavigation;
