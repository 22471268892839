import { useState } from "react";
import TabItem from "../../models/core/TabItem";
import { FaAngleDown } from "react-icons/fa";

type PropsType = {
  children?: React.ReactNode;
  items: TabItem[];
  onItemClick: (tabIndex: number) => void;
};

const Dropdown = ({ children, items, onItemClick }: PropsType) => {
  const [dropdownVisibility, setDropdownVisibility] = useState(false);

  const onClickDropdown = () => {
    setDropdownVisibility(!dropdownVisibility);
  };

  const onClickItems = (index: number) => {
    setDropdownVisibility(false);
    onItemClick(index);
  };

  return (
    <div className="flex">
      <button
        id="dropdownDefaultButton"
        data-dropdown-toggle="dropdown"
        className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
        type="button"
        onClick={onClickDropdown}
      >
        {children}
        <FaAngleDown className="w-2.5 h-2.5 ms-3" />
      </button>

      {/* <!-- Dropdown menu --> */}
      {dropdownVisibility && (
        <div className="absolute z-[1000] float-left mt-8 m-0 min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-base shadow-lg">
          <ul className="py-2 text-sm text-gray-700">
            {items.map((x: TabItem, index: number) => {
              return (
                <li key={index}>
                  <a
                    className="flex flex-row px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    onClick={() => onClickItems(index)}
                  >
                    {x.icon && x.icon}
                    {x.name}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
