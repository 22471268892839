import GameSetup from "../components/GameBoard/Game/GameSetup";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/UI/Button";
import { GiTabletopPlayers, GiPlayButton } from "react-icons/gi";
import * as LocalStorageUtil from "../utils/localStorageUtil";
import SetupGameModel from "../models/GameBoard/SetupGame";
import useToggle from "../hooks/core/useToggle";
import { getPlayerSummary } from "../apis/gamesAPI";
import {
  createGameObject,
  clearCurrentGame,
  checkIfGameIsInProgress,
} from "../utils/gamesUtil";
import { useEffect, useState } from "react";
import LoadingSpinner from "../components/UI/LoadingSpinner";

const HomePage: React.FC<{}> = (props) => {
  const [
    toggleSetupGameModal,
    setToggleSetupGameModal,
    autoToggleSetupGameModal,
  ] = useToggle(false);
  const [toggleFetchPlayer, setToggleFetchPlayer] = useToggle(false);
  const [playerSummary, setPlayerSummary] = useState([] as any[]);
  const navigate = useNavigate();
  const isGameInProgress = checkIfGameIsInProgress();

  //Setup the game context
  useEffect(() => {
    //Fetch current game player summary
    const fetchPlayerSummary = async () => {
      //fetch for creating new game
      setToggleFetchPlayer(true);
      const response = await getPlayerSummary(
        LocalStorageUtil.get(LocalStorageUtil.Key_CurrentGameId)
      );

      setToggleFetchPlayer(false);
      //throw error
      if (!response.ok) {
        throw new Error("Could not player summary for game in progress");
      } else {
        setPlayerSummary(await response.json());
      }
    };

    //Get the current game in progress
    if (isGameInProgress) {
      fetchPlayerSummary();
    }
  }, []);

  const onPlayNewGame = () => {
    //Check if current game exists
    if (LocalStorageUtil.check(LocalStorageUtil.Key_CurrentGameId)) {
      //Confirm if the current game can be discarded
      if (
        window.confirm(
          "There is a game in progress. Are you sure you want to discard the game"
        )
      ) {
        //Remove current game from local storage
        clearCurrentGame();
        //show the new game setup modal
        autoToggleSetupGameModal();
      }
    } else {
      //show the new game setup modal
      autoToggleSetupGameModal();
    }
  };

  const onGameSetup = async (gameSetup: SetupGameModel) => {
    var game = createGameObject(gameSetup);
    if (game === undefined || game?.name === undefined) {
      //hide the new game setup modal if game setup object is null
      autoToggleSetupGameModal();
      return;
    }
  };

  const onLoadGame = () => {
    navigate(
      `/games/${LocalStorageUtil.get(LocalStorageUtil.Key_CurrentGameId)}`
    );
  };

  return (
    <>
      {isGameInProgress && (
        <section className="scroll-mt-14 bg-gray-100 sm:scroll-mt-32">
          <div className="overflow-hidden lg:relative">
            <div className="mx-auto px-4 sm:px-6 md:max-w-2xl md:px-4 lg:max-w-5xl lg:px-8 relative grid grid-cols-1 items-end gap-y-12 py-20 lg:static lg:grid-cols-2 lg:py-28 xl:py-32">
              <div>
                <h2 className="mb-12 font-display text-5xl font-extrabold tracking-tight sm:w-3/4 sm:text-6xl md:w-2/3 lg:w-auto">
                  Game In Progress
                </h2>

                <Button isRounded={true} onClick={onLoadGame} className="my-4">
                  <span className="m-2 font-semibold">
                    Load Game Scoreboard
                  </span>
                </Button>
              </div>
              <div className="w-full max-w-md p-8 bg-white border border-gray-200 rounded-lg shadow  dark:bg-gray-800 dark:border-gray-700">
                {toggleFetchPlayer && <LoadingSpinner />}
                {!toggleFetchPlayer && (
                  <>
                    <div className="flex items-center justify-center mb-4">
                      <h5 className="text-xl text-center font-bold leading-none text-gray-900 dark:text-white">
                        Player Scores
                      </h5>
                    </div>
                    <div>
                      <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                        {playerSummary.map((x, index) => {
                          return (
                            <li className="py-1 sm:py-1" key={index}>
                              <div className="flex items-center">
                                <div className="flex-1 min-w-0 ms-4">
                                  <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                    {x.name}
                                  </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                  {x.score}
                                </div>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </div>{" "}
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
      <section className="scroll-mt-14 bg-[#f3c0b1] sm:scroll-mt-32">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pb-16 pt-20 text-center  lg:pt-32">
          <h1 className="mx-auto max-w-4xl font-display text-5xl font-medium tracking-tight sm:text-7xl">
            Rummy Scoring
            <span className="relative whitespace-nowrap">
              <svg
                aria-hidden="true"
                viewBox="0 0 418 42"
                className="absolute left-0 top-2/3 h-[0.58em] w-full fill-white"
                preserveAspectRatio="none"
              >
                <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path>
              </svg>
              <span className="relative capitalize">made simple</span>
            </span>
          </h1>
          <p className="mx-auto mt-6 max-w-2xl text-lg tracking-tight">
            Enter the player names and start playing. That simple. But we have
            more. Sort scored by players scores or by round. Invite friends to
            view the score live.
          </p>
          <div className="mt-10 flex flex-col sm:flex-row justify-center gap-4 sm:gap-8">
            <Link
              className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
              to="/games/new"
            >
              <GiTabletopPlayers />
              <span className="ml-3">Setup & Start Scoring</span>
            </Link>

            <button className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center me-2 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
              <GiPlayButton />
              <span className="ml-3">Watch Demo</span>
            </button>
          </div>
        </div>
      </section>
      {toggleSetupGameModal && (
        <GameSetup setGameData={onGameSetup}></GameSetup>
      )}
    </>
  );
};

export default HomePage;
