import { useRouteError } from "react-router-dom";
import MainNavigation from "../components/Layout/MainNavigation";
import HeaderLayout from "../components/Layout/Header";

const ErrorPage: React.FC<{}> = (props) => {
  const error: RouteError = useRouteError() as RouteError;

  let title = "An error occurred!";
  let message: string = "Something went wrong";

  if (error.status === 500) {
    message = error.data?.message!;
  }

  if (error.status === 404) {
    title = "Page Not found!";
    message = "Could not find resource or page you are looking for. ";
  }

  return (
    <>
      <HeaderLayout></HeaderLayout>

      <div className="flex items-center justify-center bg-white py-48">
        <div className="flex flex-col">
          <div className="flex flex-col items-center">
            <div className="text-blue-500 font-bold text-7xl">Error!</div>

            <div className="font-bold text-3xl xl:text-5xl lg:text-6xl md:text-5xl mt-10">
              {title}
            </div>

            <div className="text-gray-400 font-medium text-sm md:text-xl lg:text-2xl mt-8">
              {message}
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default ErrorPage;

class RouteError {
  data?: { message?: string };
  status?: number;
}
