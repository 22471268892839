import { ActionFunction, json, redirect, useNavigate } from "react-router-dom";
import SignupForm from "../../components/Auth/SignupForm";
import { authenticate } from "../../apis/authAPI";
import PageContentLayout from "../../components/Layout/PageContent";
import { useContext } from "react";
import { AlertsContext } from "../../state/AlertContext";
import { AlertTypes } from "../../models/core/Alert";

const SignupPage: React.FC<{}> = (props) => {
  const alertsContext = useContext(AlertsContext);
  const navigate = useNavigate();

  const singupUser = async (userDetails: any) => {
    if (
      !userDetails ||
      !userDetails.username ||
      !userDetails.password ||
      !userDetails.email
    ) {
      return false;
    }
    try {
      const response = await authenticate(userDetails, true);

      if (response.status === 400 || response.status === 404) {
        console.log(response);
        alertsContext.addAlert(
          AlertTypes.Error,
          "Email address or username already taken."
        );
      } else if (!response.ok) {
        alertsContext.addAlert(
          AlertTypes.Error,
          "Could not signup for new account. Try again!"
        );
        return false;
      } else {
        alertsContext.addAlert(
          AlertTypes.Success,
          "Account registered successfully! Please login."
        );
        navigate("/auth/login"); //  return resData;
        return true;
      }
    } catch (exception) {
      alertsContext.addAlert(
        AlertTypes.Error,
        "Unhandled exception occured. Try again later! : " + exception
      );
      return false;
    }
  };
  return (
    <>
      <PageContentLayout title="">
        <div className="flex justify-center">
          <SignupForm singupUser={singupUser}></SignupForm>
        </div>
      </PageContentLayout>
    </>
  );
};

export default SignupPage;
