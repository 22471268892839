import { GC_API_URL } from "../constants/constants";
import UserFeedback from "../models/core/Feedback";
import { getAuthToken } from "../utils/auth";

export async function submitFeedback(feedback: UserFeedback) {
  const response = await fetch(GC_API_URL + `feedback`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getAuthToken(),
    },
    body: JSON.stringify(feedback),
  });
  return response;
}