import { Link } from "react-router-dom";
import * as Yup from "yup";
import useToggle from "../../hooks/core/useToggle";
import { Form, Formik, FormikValues } from "formik";
import User from "../../models/User";
import Input from "../UI/Input";
import Button from "../UI/Button";

type PropsType = {
  loginUser: (credentails: any) => void;
};

const LoginForm = ({ loginUser }: PropsType) => {
  const [toggleSubmit, setToggleSubmit] = useToggle(false);

  const initialValues: any = {
    username: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
  });

  const onFormSubmit = async (
    values: FormikValues,
    { setSubmitting, resetForm }: any
  ) => {
    setToggleSubmit(true); // disable the button & update name
    const credentails: User = {
      username: values.username,
      password: values.password,
    };

    const isSuccess = await loginUser(credentails); //Return the data to home page for saving
    if (isSuccess!) {
      resetForm(initialValues); //reset form after success
    }
    setSubmitting(false); //Formik
    setToggleSubmit(false); // enable the button & update button name
  };

  return (
    <div className="w-full max-w-sm text-left p-4 sm:p-6 md:p-8 bg-white border border-gray-200 rounded-lg shadow">
      <h5 className="mb-4 text-xl font-medium text-center">Login!</h5>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onFormSubmit}
      >
        <Form method="post">
          <div className="flex flex-col space-y-2 sm:space-y-4">
            <Input id="username" controlName="username" fieldName="Username" />
            {/* <Link
                to="forgot-username"
                className="font-medium text-blue-600 hover:underline"
              >
                Forgot username?
              </Link> */}
            <Input
              id="password"
              controlName="password"
              fieldName="Password"
              type="password"
            />
            {/* <Link
                to="forgot-password"
                className="font-medium text-blue-600 hover:underline"
              >
                Forgot password?
              </Link> */}

            <div className="pt-4">
              <Button
                className=" w-full"
                type="submit"
                text="Login"
                submittingText="Authtenticating"
                isSubmitting={toggleSubmit}
              />

              <div className="mt-2 sm:mt-4 text-sm font-medium text-gray-500">
                Not registered?
                <Link
                  to="/auth/signup"
                  className="ml-2 text-blue-700 hover:underline"
                >
                  Create account
                </Link>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default LoginForm;
