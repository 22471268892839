const Key_CurrentGameId = "currentGameId";
const Key_GamePasscode = "gamePasscode";
const Key_GameBoardSetup = "gameBoardSetup";
const Key_GameBoardData = "gameBoardData";
export {
  Key_GameBoardSetup,
  Key_GameBoardData,
  Key_CurrentGameId,
  Key_GamePasscode,
};

const set = (key: string, data: any) => {
  localStorage.setItem(key, JSON.stringify(data));
};

const get = (key: string) => {
  if (localStorage[key]) {
    const data = localStorage.getItem(key);
    return JSON.parse(data ? data : "");
  }
};

const remove = (key: string) => {
  if (localStorage[key]) {
    localStorage.removeItem(key);
  }
};

const check = (key: string) => {
  if (localStorage[key]) {
    const data = localStorage.getItem(key);
    return JSON.parse(data ? data : "") === "" ? false : true;
  }
};

export { set, get, remove, check };
