interface scoreBoardArray {
  row?: number;
  column?: number;
  value?: string;
}

export function createArray(value: string) {
  // var array = new Array();
  // array.push(value);
  // return array;
  //return { row: 0, column:0, value } as scoreBoardArray;
  return value;
}

export function formatDate(date: any) {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  const inputDate: string = date.toString();
  const convertedDate = new Date(inputDate);
  return convertedDate.toLocaleDateString("en-US");
}

export function generatePasscode() {
  var length = 10,
    charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
    passCode = "";
  for (var i = 0, n = charset.length; i < length; ++i) {
    passCode += charset.charAt(Math.floor(Math.random() * n));
  }
  return passCode;
}
