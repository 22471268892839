import { createContext, useReducer } from "react";
import Game from "../models/Game";
import Player from "../models/Player";
import GameScore from "../models/GameScore";

type PropsType = {
  children?: React.ReactNode;
};

const GameBoardContext = createContext({
  game: {},
  roundScoring: 0,
  players: [],
  sortBy: {},
  scores: [],
  setupGame: (game: Game, players: Player[], scores: GameScore[]) => {},
  updateGame: (game: Game) => {},
  updatePlayer: (player: Player) => {},
  scoreRound: (roundScore: number) => {},
  addRoundScore: (roundNumber: number, roundScores: GameScore[]) => {},
  updateRoundScore: (roundNumber: number, roundScores: GameScore[]) => {},
  deleteRound: (roundScore: number) => {},
  updateSortOrder: (sortBy: string, sortAsc: boolean) => {},
});

function boardReducer(state: any, action: any) {
  switch (action.type) {
    case "SETUP_GAME":
      return {
        ...state,
        game: action.payload.game,
        players: action.payload.players,
        scores: action.payload.scores,
      };
    case "UPDATE_GAME":
      return null;
    case "UPDATE_PLAYER":
      return null;
    case "SCORE_ROUND":
      return {
        ...state,
        roundScoring: action.payload.roundNumber,
      };
    case "ADD_SCORE_ROUND":
      return {
        ...state,
        roundScoring: action.payload.roundNumber,
        scores: [...state.scores, ...action.payload.roundScores],
      };

    case "UPDATE_SCORE_ROUND":
      const updatedScores = [
        ...state.scores.filter(
          (x: GameScore) => x.round !== action.payload.roundNumber
        ),
        ...action.payload.roundScores,
      ];

      return {
        ...state,
        roundScoring: 0,
        scores: [...updatedScores],
      };
    case "DELETE_SCORE_ROUND":
      const afterDeletedScores = [
        ...state.scores.filter((x: GameScore) => x.round! < 9),
      ];

      return {
        ...state,
        scores: afterDeletedScores,
      };
    case "UPDATE_SORT_ORDER":
      const currentSortBy = { ...state.sortBy };
      var newSortyBy: any = {};
      if (currentSortBy.sortBy === action.payload.sortBy) {
        newSortyBy = {
          sortBy: currentSortBy.sortBy,
          sortAsc: !currentSortBy.sortAsc,
        };
      } else {
        newSortyBy = {
          sortBy: action.payload.sortBy,
          sortAsc: action.payload.sortAsc,
        };
      }

      return {
        ...state,
        sortBy: newSortyBy,
      };
  }
  return state;
}

const BoardContextProvider = ({ children }: PropsType) => {
  const [boardState, dispatchBoardActions] = useReducer(boardReducer, {
    game: {} as Game,
    roundScoring: 0,
    players: [],
    sortBy: {},
    scores: [],
  });

  function setupGame(game: Game, players: Player[], scores: GameScore[]) {
    dispatchBoardActions({
      type: "SETUP_GAME",
      payload: { game, players, scores },
    });
  }

  function updateGame(game: Game) {
    dispatchBoardActions({ type: "UPDATE_GAME", payload: null });
  }

  function updatePlayer(player: Player) {
    dispatchBoardActions({ type: "UPDATE_PLAYER", payload: null });
  }

  function scoreRound(roundNumber: number) {
    dispatchBoardActions({ type: "SCORE_ROUND", payload: { roundNumber } });
  }

  function addRoundScore(roundNumber: number, roundScores: GameScore[]) {
    dispatchBoardActions({
      type: "ADD_SCORE_ROUND",
      payload: { roundNumber, roundScores },
    });
  }
  function updateRoundScore(roundNumber: number, roundScores: GameScore[]) {
    dispatchBoardActions({
      type: "UPDATE_SCORE_ROUND",
      payload: { roundNumber, roundScores },
    });
  }

  function deleteRound(roundNumber: number) {
    dispatchBoardActions({
      type: "DELETE_SCORE_ROUND",
      payload: { roundNumber },
    });
  }

  function updateSortOrder(sortBy: string, sortAsc: boolean) {
    dispatchBoardActions({
      type: "UPDATE_SORT_ORDER",
      payload: { sortBy, sortAsc },
    });
  }

  const initialContext = {
    game: boardState.game,
    roundScoring: boardState.roundScoring,
    players: boardState.players,
    sortBy: boardState.sortBy,
    scores: boardState.scores,
    setupGame,
    updateGame,
    updatePlayer,
    scoreRound,
    addRoundScore,
    updateRoundScore,
    deleteRound,
    updateSortOrder,
  };

  return (
    <GameBoardContext.Provider value={initialContext}>
      {children}
    </GameBoardContext.Provider>
  );
};

export { GameBoardContext, BoardContextProvider };
