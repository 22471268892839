import { useContext, useEffect } from "react";
import { GameBoardContext } from "../../state/GameBoardContext";
import Button from "../UI/Button";
import { RiListOrdered2 } from "react-icons/ri";
import { PiCellSignalHighBold } from "react-icons/pi";

import {
  getMaxRound,
  checkIfIsGameOver,
  createScoreBoard,
} from "../../utils/gamesUtil";
import { addRoundScore, updateRoundScore } from "../../apis/roundScoreAPI";
import PlayerScore from "./Player/PlayerScore";
import useToggle from "../../hooks/core/useToggle";
import Game from "../../models/Game";
import ScoreByRound from "./Score/ScoreByRound";
import { AlertsContext } from "../../state/AlertContext";
import { AlertTypes } from "../../models/core/Alert";
import { updateGame } from "../../apis/gamesAPI";
import PlayerWinning from "./Player/PlayerWinning";
import TabItem from "../../models/core/TabItem";
import Dropdown from "../UI/Dropdown";

const GameBoard = () => {
  const [toggleAddGame, setToggleAddGame] = useToggle(false);
  const [toggleCloseGame, setToggleCloseGame] = useToggle(false);
  const alertsContext = useContext(AlertsContext);

  //Get context
  const boardCtx = useContext(GameBoardContext);
  const game = boardCtx.game as Game;

  //Create the score board array and extract header, rounds and footer
  var scoreBoard: any = {};
  var scoreBoardHeader: any[] = [];
  var scoreBoardRounds: any[] = [];
  var scoreBoardFooter: any[] = [];
  //Create the score board array and extract header, rounds and footer
  function loadScoreBoardData() {
    scoreBoard = createScoreBoard(
      boardCtx.game,
      boardCtx.players,
      boardCtx.scores,
      boardCtx.sortBy
    );
    scoreBoardHeader = scoreBoard.header;
    scoreBoardRounds = scoreBoard.scoreBoard;
    scoreBoardFooter = scoreBoard.footer;
  }

  //load the scoreboard;
  loadScoreBoardData();

  //Get the current round number
  const currentRound = getMaxRound(boardCtx.scores);

  //Check if the game is over
  const isGameOver = checkIfIsGameOver(boardCtx.game, boardCtx.players);
  const isGameNotClosed = isGameOver && game.resultValue !== "COMP";
  useEffect(() => {
    if (isGameNotClosed) {
      alertsContext.addAlert(
        AlertTypes.Warning,
        "There is a winner. Click Close Game to update game status."
      );
    }
  }, [isGameOver]);

  //Add new round
  const onClickAddRound = async () => {
    setToggleAddGame(true);

    try {
      //fetch for creating scores for new round
      const response = await addRoundScore(game.gameId, currentRound + 1);
      //throw error
      if (!response.ok) {
        alertsContext.addAlert(
          AlertTypes.Error,
          "Unable to add new round. Try again later."
        );
      } else {
        const gameData = await response.json();
        //Setup players for the round with 0 score
        boardCtx.addRoundScore(currentRound + 1, gameData);
        alertsContext.addAlert(
          AlertTypes.Success,
          `Round ${currentRound + 1} added successfully.`
        );
      }
    } catch (exception) {
      alertsContext.addAlert(
        AlertTypes.Error,
        "Unhandled exception occured. Try again later! : " + exception
      );
    }

    setToggleAddGame(false);
  };

  function onClickDeleteRound(round: any) {
    if (window.confirm("Are you sure you want to delete this round?")) {
      boardCtx.deleteRound(currentRound);
    }
  }

  function onClickEditRound(round: any) {
    boardCtx.scoreRound(round);
  }

  const updateRoundScores = async (roundScores: any) => {
    if (!roundScores || roundScores.length === 0) {
      alertsContext.addAlert(
        AlertTypes.Error,
        "Scores are required for all players."
      );
      return false;
    }

    try {
      //fetch for creating scores for new round
      const response = await updateRoundScore(
        game.gameId!,
        roundScores[0].round,
        roundScores
      );

      //throw error
      if (!response.ok) {
        alertsContext.addAlert(
          AlertTypes.Error,
          "Could not update round scores. Try again later."
        );
        return false;
      } else {
        //Update the round score in context
        boardCtx.updateRoundScore(boardCtx.roundScoring, roundScores);
        return true;
      }
    } catch (exception) {
      alertsContext.addAlert(
        AlertTypes.Error,
        "Unhandled exception occured. Try again later! : " + exception
      );
      return false;
    }
  };

  const updateSortOrder = (sortBy: string, sortAsc: boolean) => {
    boardCtx.updateSortOrder(sortBy, sortAsc);
  };

  const onClickCloseGame = async () => {
    setToggleCloseGame(true);
    try {
      //fetch for creating scores for new round
      game.resultValue = "COMP";
      const response = await updateGame(game.gameId!, game);
      //throw error
      if (!response.ok) {
        alertsContext.addAlert(
          AlertTypes.Error,
          "Unable to close game. Try again later."
        );
      } else {
        //Update the game in contaxt
        alertsContext.addAlert(AlertTypes.Success, "Game closed successfully");
      }
    } catch (exception) {
      alertsContext.addAlert(
        AlertTypes.Error,
        "Unhandled exception occured. Try again later! : " + exception
      );
    }
    setToggleCloseGame(false);
  };

  //Sort Dropdown
  const sortDropdown: TabItem[] = [
    { name: "Sort by Position" },
    { name: "Sort by Score" },
  ];
  const onClickDropdown = (index: number) => {
    console.log(index);
    if (index === 0) {
      updateSortOrder("PO", true);
    } else if (index === 1) {
      updateSortOrder("PS", true);
    }
  };

  return (
    <>
      {scoreBoard && (
        <>
          <div className="flex justify-between">
            <div>
              <PlayerWinning game={boardCtx.game} />{" "}
            </div>

            <div className="flex items-center space-x-2">
              <Dropdown items={sortDropdown} onItemClick={onClickDropdown}>
                Sort
              </Dropdown>
              <div className="flex gap-x-4">
                {/* <MdOutlinePlaylistAdd className="w-4 h-4 me-2" /> */}
                {!isGameOver && (
                  <Button
                    onClick={onClickAddRound}
                    type="button"
                    text="Add Round"
                    isAction={false}
                    submittingText="Saving"
                    isSubmitting={toggleAddGame}
                  />
                )}
                {isGameNotClosed && (
                  <Button
                    onClick={onClickCloseGame}
                    type="button"
                    text="Close Game"
                    submittingText="Saving"
                    isSubmitting={toggleCloseGame}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="overflow-x-auto flow-root">
            <div className="inline-block min-w-full align-middle">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="uppercase">
                  <tr>
                    {scoreBoardHeader.map((header, index) => {
                      return (
                        <th
                          className="py-4 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0"
                          key={index}
                        >
                          {header}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-400">
                  {scoreBoardRounds.map((round, index) => (
                    <tr
                      key={index}
                      className="cursor-pointer hover:bg-gray-100 hover:font-bold"
                      onClick={() => onClickEditRound(index + 1)}
                    >
                      {round.map((score: any, iindex: number) => (
                        <td
                          className="w-40 p-3 text-sm text-gray-700"
                          key={iindex}
                        >
                          <PlayerScore score={score}></PlayerScore>
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr className="border-t border-gray-300">
                    {scoreBoardFooter.map((total, index) => {
                      return (
                        <th
                          className="py-4 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0"
                          key={index}
                        >
                          {total >= game.scoreOut! && (
                            <span className="p-1 my-1 text-sm text-red-800 rounded-lg bg-red-50">
                              {total}
                            </span>
                          )}
                          {(index === 0 || total < game.scoreOut!) && (
                            <span>{total}</span>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </>
      )}

      {boardCtx.roundScoring > 0 && (
        <ScoreByRound updateRoundScores={updateRoundScores}></ScoreByRound>
      )}
    </>
  );
};

export default GameBoard;
