import { useState } from "react";
import TabItem from "../../models/core/TabItem";

type PropsType = {
  children?: React.ReactNode;
  tabs: TabItem[];
  onTabIndexChange: (tabIndex: number) => void;
};

const Tabs = ({ children, tabs, onTabIndexChange }: PropsType) => {
  const [tabIndex, setTabIndex] = useState(0);
  const tabStyles =
    "flex flex-row item-center py-2 px-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300";
  const tabStylesActive =
    "flex flex-row item-center py-2 px-4 text-orange-600 border-b-2 border-orange-600 rounded-t-lg active dark:text-orange-500 dark:border-orange-500";

  const onTabClick = (index: number) => {
    setTabIndex(index);
    onTabIndexChange(index);
  };

  return (
    <>
      <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
        <ul className="flex flex-wrap -mb-px">
          {tabs.map((x: TabItem, index: number) => {
            return (
              <li className="me-2" key={index}>
                <button
                  type="button"
                  className={index === tabIndex ? tabStylesActive : tabStyles}
                  onClick={() => onTabClick(index)}
                >
                  {x.icon && x.icon}
                  {x.name}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="mt-2 p-4">
        {children}
      </div>
    </>
  );
};

export default Tabs;
