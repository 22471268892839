const tokenKey = "token";

export function setAuthToken(token: any) {
  localStorage.setItem(tokenKey, token);
}

export function getAuthToken() {
  const token = localStorage.getItem("token");
  return token;
}

export function removeAuthToken() {
  localStorage.removeItem(tokenKey);
}

export function tokenLoader() {
  const token = getAuthToken();
  return token;
}
