import { useNavigate, useRouteLoaderData } from "react-router-dom";
import GameForm from "../../components/Games/GameForm";
import { createGame } from "../../apis/gamesAPI";
import PageContentLayout from "../../components/Layout/PageContent";
import { createGameObject, saveCurrentGameId } from "../../utils/gamesUtil";
import SetupGameModel from "../../models/GameBoard/SetupGame";
import { useContext } from "react";
import { AlertsContext } from "../../state/AlertContext";
import { AlertTypes } from "../../models/core/Alert";

const NewGamePage: React.FC<{}> = (props) => {
  const alertsContext = useContext(AlertsContext);
  const token = useRouteLoaderData("root");
  const navigate = useNavigate();

  const onGameSetup = async (gameSetup: SetupGameModel) => {
    var game = createGameObject(gameSetup);
    if (game === undefined || game?.name === undefined) {
      alertsContext.addAlert(
        AlertTypes.Error,
        "Game details and player details are required."
      );
      return false;
    }

    try {
      //fetch for creating new game
      const response = await createGame(game);

      //throw error
      if (!response.ok) {
        alertsContext.addAlert(
          AlertTypes.Error,
          "Game details and player details are required."
        );
        return false;
      } else {
        //Process the response
        const createdGame = await response.json();
        if (!token) {
          //Set the current game id to local storage
          saveCurrentGameId(createdGame.id);
        }
        //Redirect to game board
        navigate(`/games/${createdGame.id}`);
        return true;
      }
    } catch (exception) {
      alertsContext.addAlert(
        AlertTypes.Error,
        "Unhandled exception occured. Try again later! : " + exception
      );
      return false;
    }
  };

  return (
    <>
      <PageContentLayout title="Setup New Game!">
        <div className="flex justify-center">
          <GameForm onGameSetup={onGameSetup} />
        </div>
      </PageContentLayout>
    </>
  );
};

export default NewGamePage;
