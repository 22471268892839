import * as Yup from "yup";
import useToggle from "../../hooks/core/useToggle";
import { Form, Formik, FormikValues } from "formik";
import Input from "../UI/Input";
import Button from "../UI/Button";
import UserFeedback from "../../models/core/Feedback";
import ReferenceCodeValue from "../../models/core/ReferenceCodeValue";

type PropsType = {
  onSubmitFeedback: (feedback: UserFeedback) => void;
};

const FeedbackForm = ({ onSubmitFeedback }: PropsType) => {
  const [toggleSubmit, setToggleSubmit] = useToggle(false);
  const feedbackTypes: ReferenceCodeValue[] = [
    { codeValue: "ISSU", description: "Bugs / Issues / Defects" },
    { codeValue: "FEAT", description: "Feature Request" },
    { codeValue: "TESM", description: "Testimonials / Appreciation" },
    { codeValue: "OTHR", description: "Other Feedback" },
  ];

  const initialValues = {
    name: "",
    email: "",
    type: "",
    message: "",
  };

  // Formik - Example
  // https://medium.com/@kapuriya1jasmin/form-validation-in-react-with-formik-and-yup-with-typescript-f482686a2982
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    type: Yup.string().required("Type is required"),
    message: Yup.string().required("Message is required"),
  });

  const onFormSubmit = async (
    values: FormikValues,
    { setSubmitting, resetForm }: any
  ) => {
    setToggleSubmit(true); // disable the button & update name
    const feedback: UserFeedback = {
      name: values.name,
      email: values.email,
      typeValue: values.type,
      message: values.message,
    };

    const isSuccess = await onSubmitFeedback(feedback); //Return the data to parent page/component for saving
    if (isSuccess!) {
      resetForm(initialValues); //reset form after success
    }
    setSubmitting(false); //Formik
    setToggleSubmit(false); // enable the button & update button name
  };

  return (
    <div className="w-full max-w-sm text-left p-4 sm:p-6 md:p-8 bg-white border border-gray-200 rounded-lg shadow">
      <h5 className="mb-4 text-xl font-medium text-center">Submit Feedback!</h5>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onFormSubmit}
      >
        <Form method="post">
          <div className="flex flex-col space-y-2 sm:space-y-4">
            <Input id="name" controlName="name" fieldName="Full Name" />
            <Input id="email" controlName="email" fieldName="Email" />
            <Input
              id="type"
              controlName="type"
              fieldName="Feeback Type"
              inputType="select"
              options={feedbackTypes}
            />
            <Input
              id="message"
              controlName="message"
              fieldName="Message"
              component="textarea"
              rows="7"
            />
          </div>
          <div className="pt-4">
            <Button
              className=" w-full"
              type="submit"
              text="Submit"
              submittingText="Submitting"
              isSubmitting={toggleSubmit}
            />
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default FeedbackForm;
