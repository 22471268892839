import { Link } from "react-router-dom";

const NoGameFound: React.FC<{ showHistory: boolean }> = (props) => {
  return (
    <div className="flex justify-center">
      <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow space-y-6">
        <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">
          No Game found!
        </h5>
        <p className="mb-3 font-normal text-gray-500 dark:text-gray-400">
          Click the New Game button below to setup a game and start playing.
        </p>
        <Link
          className="inline-flex rounded bg-gray-800 py-1 px-3 font-medium text-white hover:bg-opacity-90 sm:py-2.5 sm:px-6"
          to="/games/new"
        >
          Setup & Start Scoring
        </Link>
        {props.showHistory && (
          <Link
            className="inline-flex rounded bg-gray-800 py-1 px-3 font-medium text-white hover:bg-opacity-90 sm:py-2.5 sm:px-6"
            to="/games"
          >
            View Past Games
          </Link>
        )}
      </div>
    </div>
  );
};

export default NoGameFound;
