import { useContext } from "react";
import { GameBoardContext } from "../../../state/GameBoardContext";
import Game from "../../../models/Game";

const GameDetails = () => {
  const gameCtx = useContext(GameBoardContext);
  const game = gameCtx.game as Game;

  return (
    <>
      <h2 className="text-2xl text-left font-medium tracking-tight text-gray-800 mb-4">
      {game.name}
      </h2>
      <dl className="max-w-sm text-gray-900 divide-y divide-gray-200 ">
        <div className="flex flex-row p-3">
          <dt className="w-1/2 text-right text-gray-500 md:text-lg">
            Player Out At:
          </dt>
          <dd className="pl-4 text-lg font-semibold">{game.scoreOut}</dd>
        </div>
        <div className="flex flex-row p-3">
          <dt className="w-1/2 text-right text-gray-500 md:text-lg">
            Drop Score:
          </dt>
          <dd className="pl-4 text-lg font-semibold">{game.scoreDrop}</dd>
        </div>
        <div className="flex flex-row p-3">
          <dt className="w-1/2 text-right text-gray-500 md:text-lg ">
            Middle drop:
          </dt>
          <dd className="pl-4 text-lg font-semibold">{game.scoreMiddleDrop}</dd>
        </div>
        <div className="flex flex-row p-3">
          <dt className="w-1/2 text-right text-gray-500 md:text-lg ">
            Full Count:
          </dt>
          <dd className="pl-4 text-lg font-semibold">{game.scoreFull}</dd>
        </div>
      </dl>
    </>
  );
};

export default GameDetails;
