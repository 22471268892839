import Game from "../../models/Game";
import GameItem from "./GameItem";

const GamesList: React.FC<{ games: Game[] }> = (props) => {
  return (
    <div className="flex justify-center">
      <div className="grid gap-6 sm:grid-cols-3">
        {props.games.map((game) => (
          <GameItem key={game.gameId} game={game}></GameItem>
        ))}
      </div>
    </div>
  );
};

export default GamesList;
