import { createContext, useReducer } from "react";
import AlertMessage, { AlertTypes } from "../models/core/Alert";

type PropsType = {
  children?: React.ReactNode;
};

const AlertsContext = createContext({
  alerts: [],
  addAlert: (
    alertType: AlertTypes,
    message: string,
    clearAlerts: boolean = true
  ) => {},
  addAlerts: (alerts: AlertMessage[], clearAlerts: boolean = true) => {},
  dismissAlert: (id: number) => {},
  dismissAllAlerts: () => {},
});

function alertsReducer(state: any, action: any) {
  switch (action.type) {
    case "ADD_ALERT":
      //Get the current alerts from state
      var alerts = [...state.alerts];
      //Clear the alerts if the flag is true
      if (action.payload.clearAlerts) {
        alerts = [];
      }
      var newAlert = action.payload.alert;
      //Find the current max id
      var currentAlertMaxId = 0;
      if (alerts && alerts.length > 0) {
        currentAlertMaxId = alerts.reduce((p, c) => (p.id > c.id ? p : c));
      }
      //Assign id to the newAlert by incrementing the max id
      newAlert.id = currentAlertMaxId + 1;

      return {
        ...state,
        alerts: [...alerts, newAlert],
      };
    case "ADD_ALERTS":
      //Get the current alerts from state
      var alerts = [...state.alerts];
      //Clear the alerts if the flag is true
      if (action.payload.clearAlerts) {
        alerts = [];
      }

      var newAlerts = action.payload.alerts;
      //Find the current max id
      var currentAlertMaxId1 = 0;
      if (alerts && alerts.length > 0) {
        currentAlertMaxId1 = alerts.reduce((p, c) => (p.id > c.id ? p : c));
      }
      //Assign id to the newAlert by incrementing the max id
      for (let index = 0; index < newAlerts.length; index++) {
        newAlerts.id = currentAlertMaxId1 + index + 1;
      }

      return {
        ...state,
        alerts: [...alerts, newAlerts],
      };
    case "DISMISS_ALERT":
      const reducedAlerts = [
        ...state.alerts.filter((x: AlertMessage) => x.id !== action.payload.id),
      ];
      return {
        ...state,
        alerts: reducedAlerts,
      };
    case "DISMISS_ALL_ALERT":
      return {
        ...state,
        alerts: [],
      };
  }
  return state;
}

const AlertsContextProvider = ({ children }: PropsType) => {
  const [alertsState, dispatchAlertsActions] = useReducer(alertsReducer, {
    alerts: [],
  });

  function addAlert(
    alertType: AlertTypes,
    message: string,
    clearAlerts: boolean = true
  ) {
    dispatchAlertsActions({
      type: "ADD_ALERT",
      payload: { alert: { type: alertType, message: message }, clearAlerts },
    });
  }

  function addAlerts(alerts: AlertMessage[], clearAlerts: boolean = true) {
    dispatchAlertsActions({
      type: "ADD_ALERTS",
      payload: { alerts, clearAlerts },
    });
  }

  function dismissAlert(id: number) {
    dispatchAlertsActions({
      type: "DISMISS_ALERT",
      payload: { id },
    });
  }

  function dismissAllAlerts() {
    dispatchAlertsActions({
      type: "DISMISS_ALERT",
    });
  }

  const initialContext = {
    alerts: alertsState.alerts,
    addAlert,
    addAlerts,
    dismissAlert,
    dismissAllAlerts,
  };

  return (
    <AlertsContext.Provider value={initialContext}>
      {children}
    </AlertsContext.Provider>
  );
};

export { AlertsContext, AlertsContextProvider };
