import { useNavigate, useParams, useRouteLoaderData } from "react-router-dom";
import GamesBoard from "../../components/GameBoard/GameBoard";
import PageContentLayout from "../../components/Layout/PageContent";
import { GameBoardContext } from "../../state/GameBoardContext";
import { useContext, useEffect } from "react";
import * as LocalStorageUtil from "../../utils/localStorageUtil";
import { getGameBoard } from "../../apis/gamesAPI";
import NoGameFound from "../../components/GameBoard/Game/NoGameFound";
import useToggle from "../../hooks/core/useToggle";
import LoadingSpinner from "../../components/UI/LoadingSpinner";
import { AlertsContext } from "../../state/AlertContext";
import { AlertTypes } from "../../models/core/Alert";
import useTabIndex from "../../hooks/core/useTabIndex";
import GameDetails from "../../components/GameBoard/Game/GameDetails";
import Tabs from "../../components/UI/Tabs";
import TabItem from "../../models/core/TabItem";
import { FaClipboardList } from "react-icons/fa";
import { CgDetailsMore } from "react-icons/cg";
import { GiTabletopPlayers } from "react-icons/gi";
import PlayerDetails from "../../components/GameBoard/Player/PlayerDetails";

const GamesBoardPage: React.FC<{}> = (props) => {
  const alertsContext = useContext(AlertsContext);
  const boardCtx = useContext(GameBoardContext);
  const navigate = useNavigate();
  const token = useRouteLoaderData("root");
  const [toggleLoading, setToggleLoading] = useToggle(true);
  const [gamesTabIndex, setGamesTabIndex] = useTabIndex(0);
  const { gameId } = useParams();
  var currentGameId = 0;

  //Setup the game context on page load
  useEffect(() => {
    //Fetch baord game data
    const fetchGameBoardData = async (gameId: number) => {
      try {
        //fetch for creating new game
        const response = await getGameBoard(gameId);
        if (!response.ok) {
          boardCtx.setupGame({}, [], []);
          alertsContext.addAlert(
            AlertTypes.Error,
            "Unable to load the game details."
          );
        } else {
          //Setup the context from response
          const gameData = await response.json();
          boardCtx.setupGame(gameData.game, gameData.players, gameData.scores);
        }
      } catch (exception) {
        alertsContext.addAlert(
          AlertTypes.Error,
          "Unhandled exception occured. Try again later! : " + exception
        );
      }
      setToggleLoading(false);
    };

    //Get the current game id from param, local storage or from server based on logged in user

    if (gameId && +gameId > 0) {
      //Load from params
      currentGameId = +gameId;
    } else if (LocalStorageUtil.check(LocalStorageUtil.Key_CurrentGameId)) {
      //load from local storage
      currentGameId = LocalStorageUtil.get(LocalStorageUtil.Key_CurrentGameId);
    } else if (token) {
      navigate("/games");
    }

    if (currentGameId > 0) {
      fetchGameBoardData(currentGameId);
    } else {
      setToggleLoading(false);
    }
  }, []);

  //Game tabs
  const gameTabItems: TabItem[] = [
    { name: "Scoreboard", icon: <FaClipboardList className="h-4 w-4 mr-2" /> },
    { name: "Players", icon: <GiTabletopPlayers className="h-4 w-4 mr-2" /> },
    { name: "Game Details", icon: <CgDetailsMore className="h-4 w-4 mr-2" /> },
  ];

  const onTabIndexChange = (index: number) => {
    setGamesTabIndex(index);
    return;
  };

  return (
    <>
      <PageContentLayout title="">
        {/* Loading Data */}
        {toggleLoading && <LoadingSpinner />}
        {/* No game Loaded */}
        {!toggleLoading &&
          (!boardCtx.scores || boardCtx.scores.length === 0) && (
            <NoGameFound showHistory={true} />
          )}
        {/* Game Loaded */}
        {boardCtx.scores && boardCtx.scores.length > 0 && (
          <>
            <Tabs tabs={gameTabItems} onTabIndexChange={onTabIndexChange}>
              {gamesTabIndex === 0 && (
                <>
                  <GamesBoard />
                </>
              )}
              {gamesTabIndex === 1 && <PlayerDetails />}

              {gamesTabIndex === 2 && <GameDetails />}
            </Tabs>
          </>
        )}
      </PageContentLayout>
    </>
  );
};

export default GamesBoardPage;
