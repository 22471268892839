const PageContentLayout: React.FC<{
  children: any;
  title: string;
  actions?: any;
}> = (props) => {
  return (
    <>
      <main className="max-w-screen-lg px-4 mb-8">
        {props.title && (
          <div className="flex justify-between mb-4 sm:mb-8 pb-2 border-b border-gray-200">
            <div>
              <h1 className="inline-block text-2xl sm:text-3xl font-extrabold tracking-tight">
                {props.title}
              </h1>
            </div>
            <div>{props.actions}</div>
          </div>
        )}
        {props.children}
      </main>
    </>
  );
};

export default PageContentLayout;
