import { redirect } from "react-router-dom";
import { removeAuthToken } from "../../utils/auth";
import { useContext } from "react";
import { AlertsContext } from "../../state/AlertContext";
import { AlertTypes } from "../../models/core/Alert";

export function logoutAction() {
  //const alertsContext = useContext(AlertsContext);

  removeAuthToken();
  //alertsContext.addAlert(AlertTypes.Success, "You are logged out!");
  return redirect("/auth/login");
}
