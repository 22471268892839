// https://levelup.gitconnected.com/supercharge-your-typescript-react-application-with-these-essential-utilities-913b9a40f2fe

import Game from "../models/Game";
import SetupGameModel from "../models/GameBoard/SetupGame";
import GameScore from "../models/GameScore";
import Player from "../models/Player";
import * as LocalStorageUtil from "../utils/localStorageUtil";
import { createArray, generatePasscode } from "./commonUtil";

// ----------- Game Setup -----------
export function createGameObject(gameSetup: SetupGameModel): Game | null {
  if (gameSetup === undefined || gameSetup.players === undefined) {
    return null;
  }
  //Create the players collection
  const gamePlayers: Player[] = gameSetup
    .players!.replace(/\n/g, "|")
    .split("|")
    .map(
      (p, index) =>
        ({
          name: p,
          gamePosition: index + 1,
        } as Player)
    );

  //Create game object and assign the player array
  const gameData: Game = {
    passcode: saveGamePasscode(),
    name: gameSetup.gameName,
    scoreOut: gameSetup.scoreOut,
    scoreDrop: gameSetup.scoreDrop,
    scoreMiddleDrop: gameSetup.scoreMiddleDrop,
    scoreFull: gameSetup.scoreFull,
    players: gamePlayers,
  };

  return gameData;
}

//#region Current Game

export function saveCurrentGameId(id: number) {
  LocalStorageUtil.set(LocalStorageUtil.Key_CurrentGameId, id);
}
export function getCurrentGameId() {
  const gameId = LocalStorageUtil.get(LocalStorageUtil.Key_CurrentGameId);
  return gameId !== undefined ? gameId : null;
}
export function clearCurrentGame() {
  LocalStorageUtil.remove(LocalStorageUtil.Key_CurrentGameId);
  LocalStorageUtil.remove(LocalStorageUtil.Key_GamePasscode);
}
export function saveGamePasscode() {
  const passcode = generatePasscode();
  LocalStorageUtil.set(LocalStorageUtil.Key_GamePasscode, passcode);
  return passcode;
}
export function getGamePasscode() {
  const passCode = LocalStorageUtil.get(LocalStorageUtil.Key_GamePasscode);
  return passCode !== undefined ? passCode : null;
}
export function checkIfGameIsInProgress() {
  return LocalStorageUtil.get(LocalStorageUtil.Key_CurrentGameId) > 0
    ? true
    : false;
}

//#endregion

export function checkIfIsGameOver(game: Game, players: Player[]): boolean {
  const scoreOut = game.scoreOut;
  return (
    //check if the totalScore property exists or if the total score is less than game scoreOut(201)
    players.filter(
      (player: Player) =>
        !Object.hasOwn(player, "totalScore") || player.totalScore! < scoreOut!
    )?.length <= 1
  );
}

export function getGamePlayerScoreSummary(game: Game): any {
  const playerNames = game.playerNames?.split("|$|");
  const playerScores = game.playerScores?.split("|$|");
  const scores: any = [];
  if (playerNames && playerNames.length > 0) {
    for (let index = 0; index < playerNames?.length!; index++) {
      scores.push({
        name: playerNames[index],
        score: playerScores![index],
        isWinner: false,
      });
    }

    scores.sort((a: any, b: any) => {
      return a.score! - b.score!;
    });
    scores[0].isWinner = true;
  }
  return scores;
}

export function getMaxRound(scores: GameScore[]): number {
  if (scores === undefined || scores.length === 0) {
    return 0;
  }
  return Math.max(...scores.map((o: GameScore) => o.round!));
}

export function checkIfRoundIsScored(scores: GameScore[]): boolean {
  return scores.filter((x: GameScore) => x.score === 0).length === 1;
}

export function getRoundScores(
  scores: GameScore[],
  round: number
): GameScore[] | null {
  const scored = scores.filter((x: GameScore) => x.round === round);
  return scored ? scored : null;
}

export function getPlayerById(
  players: Player[],
  playerId: number
): Player | null {
  const player = players.find((x: Player) => x.playerId === playerId);
  return player ? player : null;
}

export function calculatePlayerTotalsRoundOut(
  game: Game,
  players: Player[],
  scores: GameScore[]
) {
  //Upate Player total score and round out at
  players.forEach((player: Player) => {
    const filteredPlayerScores = scores.filter(
      (x: GameScore) => x.playerId === player.playerId
    );
    player.totalScore = 0;
    player.totalScore = filteredPlayerScores?.reduce(
      (accum, item) => accum + +item.score!,
      0
    );
    player.roundOutAt = 0;
    if (player.totalScore >= game.scoreOut!) {
      player.roundOutAt = Math.max(
        ...filteredPlayerScores
          .filter((x: GameScore) => x.score! > 0)
          ?.map((o: GameScore) => o.round!)
      );
    }
  });

  return players;
}

export function createScoreBoard(
  game: Game,
  players: Player[],
  scores: GameScore[],
  sortBy: any
) {
  const maxRound =
    scores && scores.length > 0 ? Math.max(...scores.map((o) => o.round!)) : 0;

  players = calculatePlayerTotalsRoundOut(game, players, scores);

  if (sortBy.sortBy === "PO") {
    if (sortBy.sortAsc) {
      players.sort((a, b) => {
        return a.gamePosition! - b.gamePosition!;
      });
    } else {
      players.sort((a, b) => {
        return b.gamePosition! - a.gamePosition!;
      });
    }
  } else if (sortBy.sortBy === "PS") {
    if (sortBy.sortAsc) {
      players.sort((a, b) => {
        return a.totalScore! - b.totalScore!;
      });
    } else {
      players.sort((a, b) => {
        return b.totalScore! - a.totalScore!;
      });
    }
  }

  //declare the header array
  var header: any[] = [];
  header.push(createArray("Round"));
  players.forEach((player: Player) => {
    //Add players to header array
    header.push(createArray(player.name!));
  });

  var scoreBoard: any[] = [];

  for (let i = 1; i <= maxRound; i++) {
    //declare the round score array
    //Will be declared for each round
    var roundScore: any[] = [];
    //Add round number
    roundScore.push(createArray(i.toString()));

    const countPlayersInRound = players.filter(
      (x: Player) => !x.roundOutAt || (x.roundOutAt! > 0 && i <= x.roundOutAt!)
    )?.length;
    const countPlayersWithZeroScore = scores.filter(
      (x: GameScore) => x.round === i && x.score === 0
    )?.length;
    const roundComplete =
      countPlayersWithZeroScore === 1 ||
      players.length - countPlayersInRound === countPlayersWithZeroScore - 1
        ? true
        : false;

    players.forEach((player: Player) => {
      var score: string = "";
      //If the player did not join the round, leave the score blank
      if (player.roundJoined! > i) {
        score = "";
      } //if the player is out in prior round, mark the score as 'X'
      else if (
        player.roundOutAt &&
        player.roundOutAt! > 0 &&
        i > player.roundOutAt!
      ) {
        score = "X";
      } else {
        //Get the player scores
        const playerRoundScore = scores.find(
          (x: GameScore) => x.playerId === player.playerId && x.round === i
        );

        //If the player score exists for the round
        if (playerRoundScore) {
          //if the player score for the round is 0 and the round is complete, mark the score as 'X'
          if (roundComplete && playerRoundScore.score === 0) {
            score = "W";
          }
          //else set the round score as the score
          else {
            score = playerRoundScore.score!.toString();
          }
        }
      }
      roundScore.push(createArray(score));
    });
    //const roundScoreItem = { round: i, roundScore: roundScore };
    scoreBoard.push(roundScore);
  }

  var footer: any[] = [];
  footer.push(createArray("Total"));
  players.forEach((player: Player) => {
    footer.push(createArray(player.totalScore!.toString()));
  });

  return { header: header, scoreBoard: scoreBoard, footer: footer };
}
