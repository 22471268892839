import { GC_API_URL } from "../constants/constants";
import { getAuthToken } from "../utils/auth";
import { getGamePasscode } from "../utils/gamesUtil";

export async function addRoundScore(gameId: any, roundNumber: any) {
  const response = await fetch(
    GC_API_URL +
      `games/${gameId}/round/${roundNumber}?gamePasscode=${getGamePasscode()}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getAuthToken(),
      },
      body: JSON.stringify({ value: "" }),
    }
  );
  return response;
}

export async function updateRoundScore(
  gameId: number,
  roundNumber: number,
  scores: any
) {
  const response = await fetch(
    GC_API_URL +
      `games/${gameId}/round/${roundNumber}?gamePasscode=${getGamePasscode()}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getAuthToken(),
      },
      body: JSON.stringify(scores),
    }
  );
  return response;
}

export async function deleteRoundScore(gameId: number, roundNumber: number) {
  const response = await fetch(
    GC_API_URL +
      `games/${gameId}/round/${roundNumber}?gamePasscode=${getGamePasscode()}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getAuthToken(),
      },
    }
  );
  return response;
}
