import GameScore from "../../../models/GameScore";
import { GiTrophyCup } from "react-icons/gi";
import { GiCrossMark } from "react-icons/gi";
import { checkIfRoundIsScored } from "../../../utils/gamesUtil";
import { useContext } from "react";
import { GameBoardContext } from "../../../state/GameBoardContext";

type PropsType = {
  children?: React.ReactNode;
  score?: string;
};

const PlayerScore = ({ score }: PropsType) => {
  return (
    <>
      {score === "W" && (
        <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-full dark:bg-green-800 dark:text-green-200">
          <GiTrophyCup className="w-5 h-5" />
          <span className="sr-only">Won</span>
        </div>
      )}
      {score === "X" && (
        <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-full dark:bg-red-800 dark:text-red-200">
          <GiCrossMark className="w-5 h-5" />
          <span className="sr-only">Out</span>
        </div>
      )}
      {score !== "X" && score !== "W" && score}
    </>
  );
};

export default PlayerScore;
