class AlertMessage {
    id?: number;
    type?: AlertTypes;
    message?: string;
}

export default AlertMessage;

export enum AlertTypes {
    Info = "Info",
    Error = "Error",
    Success = "Success",
    Warning = "Warning",
    Promo = "Promo",
} 