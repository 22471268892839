import { RouterProvider } from "react-router-dom";
import appRouter from "./AppRouter";

function App() {
  return (
    <>
      <div
        className="w-full h-screen "
        // className="w-full h-full min-h-full bg-no-repeat bg-cover"
        // style={{
        //   backgroundImage: `url(https://images.pexels.com/photos/3290047/pexels-photo-3290047-99999999.jpeg)`,
        // }}
      >
        <RouterProvider router={appRouter} />
      </div>
    </>
  );
}

export default App;
