import { useContext } from "react";
import GameScore from "../../../models/GameScore";
import * as Yup from "yup";
import { GameBoardContext } from "../../../state/GameBoardContext";
import Player from "../../../models/Player";
import { FieldArray, Form, Formik, FormikValues } from "formik";
import Input from "../../UI/Input";
import Modal from "../../UI/Modal";
import Button from "../../UI/Button";
import Game from "../../../models/Game";
import useToggle from "../../../hooks/core/useToggle";
import { calculatePlayerTotalsRoundOut } from "../../../utils/gamesUtil";

type PropsType = {
  children?: React.ReactNode;
  updateRoundScores: (roundScores: GameScore[]) => void;
};

const ScoreByRound = ({ updateRoundScores }: PropsType) => {
  const [toggleSubmit, setToggleSubmit] = useToggle(false);
  const boardCtx = useContext(GameBoardContext);

  const fullCountScore = (boardCtx.game as Game).scoreFull;

  //Get players with total score and round out at if they are out
  const players: Player[] = calculatePlayerTotalsRoundOut(
    boardCtx.game,
    boardCtx.players,
    boardCtx.scores
  );
  var playresPlaying = [
    ...players.filter(
      (x: Player) =>
        !x.roundOutAt ||
        (x.roundOutAt! > 0 && x.roundOutAt! >= boardCtx.roundScoring)
    ),
  ];
  var playresNotPlaying = [
    ...players.filter(
      (x: Player) =>
        x.roundOutAt &&
        x.roundOutAt! > 0 &&
        x.roundOutAt! < boardCtx.roundScoring
    ),
  ];

  playresPlaying.sort((a: Player, b: Player) => {
    return a.gamePosition! - b.gamePosition!;
  });

  const playersScores = boardCtx.scores.filter(
    (x: GameScore) => x.round === boardCtx.roundScoring
  );

  const playersArray: any = [];
  for (let i = 0; i < playresPlaying.length; i++) {
    const playerScore: any = playersScores.find(
      (x: GameScore) => x.playerId === playresPlaying[i].playerId
    );

    playersArray.push({
      name: playresPlaying[i].name,
      playerId: playresPlaying[i].playerId,
      score: playerScore.score,
    });
  }
  const initialValues = {
    players: playersArray,
  };

  const validationSchema = Yup.object().shape({
    players: Yup.array().of(
      Yup.object().shape({
        score: Yup.number()
          .min(0, "Score must be between 0 and " + fullCountScore)
          .max(fullCountScore!, "Score must be between 0 and " + fullCountScore)
          .required("Score is Required"),
      })
    ),
  });

  const onFormSubmit = async (
    values: FormikValues,
    { setSubmitting, resetForm }: any
  ) => {
    setToggleSubmit(true);
    playersScores.forEach((element: any) => {
      var playerScore = values.players.find(
        (x: any) => x.playerId === element.playerId
      );
      if (playerScore) {
        element.score = playerScore.score;
      }
    });
    const isSuccess = await updateRoundScores(playersScores);
    if (isSuccess!) {
      resetForm(initialValues); //reset form after success
    }
    setSubmitting(false);
    setToggleSubmit(false);
  };

  const onModalCancel = () => {
    boardCtx.scoreRound(0);
  };

  return (
    <>
      <Modal
        title={`Score Round ${boardCtx.roundScoring}`}
        open={true}
        className=""
        onClose={onModalCancel}
      >
        <div className="w-full max-w-sm text-left p-4 sm:p-6 md:p-8 bg-white border border-gray-200 rounded-lg shadow">
          {playresNotPlaying && playresNotPlaying.length > 0 && (
            <div className="flex flex-col py-2 mb-4 border-b border-gray-200">
              <span className="font-semibold">Players Out This Round:</span>
              <div className="flex flex-row space-x-2 ">
                {playresNotPlaying.map((x: Player) => {
                  return (
                    <span
                      key={x.playerId}
                      className="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded  border border-red-300"
                    >
                      {x.name}
                    </span>
                  );
                })}
              </div>
            </div>
          )}

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onFormSubmit}
          >
            {({ values }) => (
              <Form method="post">
                <div className="flex flex-col space-y-2 sm:space-y-4">
                  <FieldArray name="players">
                    {({ insert, remove, push }) => (
                      <div className="flex flex-col space-y-2 sm:space-y-4">
                        {values.players.length > 0 &&
                          values.players.map((player: any, index: number) => (
                            <Input
                              id={`players.${index}.score`}
                              controlName={`players.${index}.score`}
                              fieldName={player.name}
                              key={index}
                            />
                          ))}
                      </div>
                    )}
                  </FieldArray>
                  <div className="mt-8 mb-4 flex justify-center gap-x-6">
                    <Button
                      className=" w-full"
                      type="submit"
                      text="Update Score"
                      submittingText="Updating"
                      isSubmitting={toggleSubmit}
                    />
                    <Button
                      isAction={false}
                      type="button"
                      onClick={onModalCancel}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </>
  );
};

export default ScoreByRound;
