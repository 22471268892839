import { getGames, searchGames } from "../../apis/gamesAPI";
import GamesList from "../../components/Games/GamesList";
import Game from "../../models/Game";
import {
  Link,
  json,
  useLoaderData,
  useRouteLoaderData,
} from "react-router-dom";
import PageContentLayout from "../../components/Layout/PageContent";
import NoGameFound from "../../components/GameBoard/Game/NoGameFound";
import {
  checkIfGameIsInProgress,
  getCurrentGameId,
  getGamePasscode,
} from "../../utils/gamesUtil";
import { getAuthToken } from "../../utils/auth";

const GamesPage: React.FC<{}> = (props) => {
  const response: any = useLoaderData();

  return (
    <PageContentLayout
      title="Games Histroy!"
      actions={
        response?.data?.length > 0 && (
          <div>
            <Link
              className="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 me-2 mb-2"
              to="new"
            >
              New Game!
            </Link>
          </div>
        )
      }
    >
      {response?.data?.length > 0 && (
        <GamesList
          games={response?.data !== undefined ? (response?.data as Game[]) : []}
        />
      )}

      {(!response.data || response.data.length === 0) && (
        <NoGameFound showHistory={false} />
      )}
    </PageContentLayout>
  );
};

export default GamesPage;

export async function gamesLoader() {
  const token = getAuthToken();
  if (token) {
    const response = await getGames();

    if (!response.ok) {
      throw json(
        { message: "Could not load games data. Please try again!" },
        { status: 500 }
      );
    } else {
      const resData = await response.json();
      return resData;
    }
  }
  //search based on the game id from local storage
  else if (checkIfGameIsInProgress()) {
    const response = await searchGames({
      id: getCurrentGameId(),
      gamePasscode: getGamePasscode(),
    });

    if (!response.ok) {
      throw json(
        { message: "Could not load games data. Please try again!" },
        { status: 500 }
      );
    } else {
      const resData = await response.json();
      return resData;
    }
  } else {
    return [];
  }
}
