import { useContext } from "react";
import { GameBoardContext } from "../../../state/GameBoardContext";
import Game from "../../../models/Game";
import {
  checkIfIsGameOver,
  getGamePlayerScoreSummary,
} from "../../../utils/gamesUtil";

type PropsType = {
  children?: React.ReactNode;
  game: Game;
};

const PlayerWinning = ({ game }: PropsType) => {
  const scores: any[] = getGamePlayerScoreSummary(game);
  const winningPlayer = scores && scores.length > 0 ? scores[0] : {};

  return (
    <>
      <h1 className="my-4 text-4xl font-headline font-black tracking-snug text-center leading-12 sm:leading-15 md:leading-19 lg:leading-26 text-gray-800">
        <span className="text-transparent bg-clip-text bg-gradient-to-br from-yellow-300 to-red-500 whitespace-pre-wrap">
          {game.resultValue === "COMP"
            ? `${winningPlayer.name} won!`
            : `${winningPlayer.name} \r is \r leading!`}
        </span>
      </h1>
    </>
  );
};

export default PlayerWinning;
