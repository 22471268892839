import { useContext } from "react";
import { AlertsContext } from "../../state/AlertContext";
import AlertMessage, { AlertTypes } from "../../models/core/Alert";
import { LiaWindowCloseSolid } from "react-icons/lia";

const Alerts = ({ children, ...props }: any) => {
  const alertsContext = useContext(AlertsContext);

  const errorAlerts = alertsContext.alerts?.filter(
    (x: AlertMessage) => x.type === AlertTypes.Error
  );

  const successAlerts = alertsContext.alerts?.filter(
    (x: AlertMessage) => x.type === AlertTypes.Success
  );

  const infoAlerts = alertsContext.alerts?.filter(
    (x: AlertMessage) => x.type === AlertTypes.Info
  );

  const warningAlerts = alertsContext.alerts?.filter(
    (x: AlertMessage) => x.type === AlertTypes.Warning
  );

  const promoAlerts = alertsContext.alerts?.filter(
    (x: AlertMessage) => x.type === AlertTypes.Promo
  );

  const closeAlert = (id: number) => {
    alertsContext.dismissAlert(id);
  };

  return (
    <>
      {alertsContext.alerts.length > 0 && (
        <div className="bg-gray-50">
          <div className="p-2 max-w-screen-sm mx-auto">
            {/* Error Alerts */}
            {errorAlerts?.length > 0 && (
              <div className="p-4 text-red-800 rounded-lg bg-red-50">
                <ul>
                  {errorAlerts.map((x: AlertMessage) => {
                    return (
                      <li className="flex items-center my-1" key={x.id}>
                        <svg
                          className="flex-shrink-0 w-4 h-4"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                        </svg>
                        <span className="sr-only">Error</span>
                        <div className="ms-3 text-sm font-medium">
                          {x.message}
                        </div>
                        <button
                          type="button"
                          className="ms-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8"
                          onClick={() => {
                            closeAlert(x.id!);
                          }}
                        >
                          <span className="sr-only">Close</span>
                          <LiaWindowCloseSolid />
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            {/* Success alerts */}
            {successAlerts?.length > 0 && (
              <div className="p-4 text-green-800 rounded-lg bg-green-50">
                <ul>
                  {successAlerts.map((x: AlertMessage) => {
                    return (
                      <li className="flex items-center my-1" key={x.id}>
                        <svg
                          className="flex-shrink-0 w-4 h-4"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                        </svg>
                        <span className="sr-only">Success</span>
                        <div className="ms-3 text-sm font-medium">
                          {x.message}
                        </div>
                        <button
                          type="button"
                          className="ms-auto -mx-1.5 -my-1.5 bg-green-50 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex items-center justify-center h-8 w-8"
                          onClick={() => {
                            closeAlert(x.id!);
                          }}
                        >
                          <span className="sr-only">Close</span>
                          <LiaWindowCloseSolid />
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            {/* Info alerts */}
            {infoAlerts?.length > 0 && (
              <div className="p-4 text-blue-800 rounded-lg bg-blue-50">
                <ul>
                  {infoAlerts.map((x: AlertMessage) => {
                    return (
                      <li className="flex items-center my-1" key={x.id}>
                        <svg
                          className="flex-shrink-0 w-4 h-4"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                        </svg>
                        <span className="sr-only">Info</span>
                        <div className="ms-3 text-sm font-medium">
                          {x.message}
                        </div>
                        <button
                          type="button"
                          className="ms-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 inline-flex items-center justify-center h-8 w-8"
                          onClick={() => {
                            closeAlert(x.id!);
                          }}
                        >
                          <span className="sr-only">Close</span>
                          <LiaWindowCloseSolid />
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            {/* Info alerts */}
            {warningAlerts?.length > 0 && (
              <div className="p-4 text-yellow-800 rounded-lg bg-yellow-50">
                <ul>
                  {warningAlerts.map((x: AlertMessage) => {
                    return (
                      <li className="flex items-center my-1" key={x.id}>
                        <svg
                          className="flex-shrink-0 w-4 h-4"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                        </svg>
                        <span className="sr-only">Info</span>
                        <div className="ms-3 text-sm font-medium">
                          {x.message}
                        </div>
                        <button
                          type="button"
                          className="ms-auto -mx-1.5 -my-1.5 bg-yellow-50 text-yellow-500 rounded-lg focus:ring-2 focus:ring-yellow-400 p-1.5 hover:bg-yellow-200 inline-flex items-center justify-center h-8 w-8"
                          onClick={() => {
                            closeAlert(x.id!);
                          }}
                        >
                          <span className="sr-only">Close</span>
                          <LiaWindowCloseSolid />
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Alerts;
