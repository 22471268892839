import Modal from "../../UI/Modal";
import { Form, Formik, FormikValues } from "formik";
import * as Yup from "yup";
import Input from "../../UI/Input";
import Button from "../../UI/Button";
import SetupGameModel from "../../../models/GameBoard/SetupGame";
import useToggle from "../../../hooks/core/useToggle";

type PropsType = {
  setGameData: (game: SetupGameModel) => void;
};

const GameSetup = ({ setGameData }: PropsType) => {
  const [toggleSaveGame, setToggleSaveGame] = useToggle(false);
  
  const initialValues: SetupGameModel = {
    gameName: "",
    players: "",
  };
  
  // Formik - Example
  // https://medium.com/@kapuriya1jasmin/form-validation-in-react-with-formik-and-yup-with-typescript-f482686a2982
  const validationSchema = Yup.object().shape({
    gameName: Yup.string().required("Game Name is required"),
    players: Yup.string().required("Players are required"),
  });

  const onModalCancel = () => {
    setGameData({});
  };

  const onFormSubmit = (values: FormikValues, { setSubmitting }: any) => {
    const game: SetupGameModel = {
      gameName: values.gameName,
      scoreOut: 201,
      scoreDrop: 20,
      scoreMiddleDrop: 40,
      scoreFull: 80,
      players: values.players,
    };

    setToggleSaveGame(true); // disable the button & update name
    setGameData(game); //Return the data to home page for saving
    setSubmitting(false); //Formik
    setToggleSaveGame(true); // enable the button & update name
  };

  return (
    <Modal title="Setup Game!" open={true} className="" onClose={onModalCancel}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onFormSubmit}
      >
        <Form className="max-w-sm mx-auto">
          <div className="mb-4">
            <Input
              id="gameName"
              controlName="gameName"
              fieldName="Game Name"
              placeholder="e.g. Ravi's Home"
            />
          </div>
          <div className="mb-4">
            <Input
              id="players"
              controlName="players"
              fieldName="Players"
              placeholder={`Prakash\nMadhu\nNalan\nLakshmi\nShekar\nNaveen\nNandhu`}
              component="textarea"
              rows="7"
            />
          </div>
          <div className="mt-8 mb-4 flex justify-center gap-x-6">
            <Button type="submit" disabled={toggleSaveGame}>
              {toggleSaveGame ? "Saving..." : "Setup Game"}
            </Button>
            <Button isAction={false} type="button" onClick={onModalCancel}>
              Cancel
            </Button>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default GameSetup;
