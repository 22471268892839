import { ActionFunction, json, redirect, useSubmit } from "react-router-dom";
import { authenticate } from "../../apis/authAPI";
import LoginForm from "../../components/Auth/LoginForm";
import { setAuthToken } from "../../utils/auth";
import PageContentLayout from "../../components/Layout/PageContent";
import {
  checkIfGameIsInProgress,
  clearCurrentGame,
  getCurrentGameId,
} from "../../utils/gamesUtil";
import { associateGameToUser } from "../../apis/gamesAPI";
import { useContext } from "react";
import { AlertsContext } from "../../state/AlertContext";
import { AlertTypes } from "../../models/core/Alert";

const LoginPage: React.FC<{}> = (props) => {
  const alertsContext = useContext(AlertsContext);
  const submit = useSubmit();

  const onLogin = async (credentails: any) => {
    if (!credentails || !credentails.username || !credentails.password) {
      alertsContext.addAlert(
        AlertTypes.Error,
        "Username and password are required."
      );
      return false;
    }
    try {
      const response = await authenticate(credentails, false);
      if (response.status === 401) {
        alertsContext.addAlert(
          AlertTypes.Error,
          "Unable to authorize user. Enter valid credentials"
        );
        return false;
      } else if (!response.ok) {
        alertsContext.addAlert(
          AlertTypes.Error,
          "Could not authenticate user. Please try again!"
        );
        return false;
      } else {
        const resData = await response.json();
        //Save the token
        setAuthToken(resData.accessToken);

        //if there is a game saved locally
        if (checkIfGameIsInProgress()) {
          //Assocated the game to the logged in user
          await updateGameToUser();
        }
        submit(null, { method: "post", action: "/auth/login" });
        return true;
      }
    } catch (exception) {
      alertsContext.addAlert(
        AlertTypes.Error,
        "Unhandled exception occured. Try again later! : " + exception
      );
      return false;
    }
  };

  const updateGameToUser = async () => {
    //fetch for associating the game in progress to user

    const response = await associateGameToUser(getCurrentGameId());

    //throw error
    if (!response.ok) {
      alertsContext.addAlert(
        AlertTypes.Error,
        "Could not associate game in progress to your account!"
      );
    } else {
    } //Do nothing
  };

  return (
    <>
      <PageContentLayout title="">
        <div className="flex justify-center">
          <LoginForm loginUser={onLogin}></LoginForm>
        </div>
      </PageContentLayout>
    </>
  );
};

export default LoginPage;

export const loginAuthAction: ActionFunction = async ({ request, params }) => {
  //Get game id if the is a game in progress
  const gameId = getCurrentGameId();
  //Remove the current gmae id and passcode from local storage
  clearCurrentGame();

  //If game is in progress, load the game board
  if (gameId > 0) {
    return redirect(`/games/${gameId}`);
  }

  //else load the game
  return redirect("/games");
};
