import { GC_API_URL } from "../constants/constants";

export async function authenticate(user: any, isNewUser: boolean) {
  const response = await fetch(
    isNewUser
      ? GC_API_URL + "authentication"
      : GC_API_URL + "authentication/login",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(user),
    }
  );
  return response;
}


