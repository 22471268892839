import { Link, useLocation } from "react-router-dom";
import MainNavigation from "./MainNavigation";
import { GiCardJoker } from "react-icons/gi";
import useToggle from "../../hooks/core/useToggle";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";
import { useEffect } from "react";

const HeaderLayout: React.FC<{}> = (props) => {
  const [toggleMenu, setToggleMenu, autoToggleMenu] = useToggle(false);
  const { pathname } = useLocation();

  //Close the navigation panel on route change
  //Source: https://stackoverflow.com/questions/46244279/how-to-close-navigation-menu-on-route-change
  useEffect(() => {
    setToggleMenu(false);
  }, [pathname]);

  return (
    <header className="border-b border-gray-200">
      <div className="max-w-screen-lg mx-auto flex flex-wrap items-center justify-between p-4">
        <Link className="flex items-center" to="/">
          <GiCardJoker className="w-12 h-12 p-1 bg-gradient-to-r from-red-500 to-orange-300 text-white" />
          <span className="self-center p-2 text-2xl font-semibold whitespace-nowrap">
            Rummy Scorer
          </span>
        </Link>

        {/* Mobile Menu hamburg */}
        <button
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          onClick={autoToggleMenu}
        >
          <span className="sr-only">Open main menu</span>
          {toggleMenu ? (
            <IoMdClose className="w-5 h-5" />
          ) : (
            <GiHamburgerMenu className="w-5 h-5" />
          )}
        </button>

        <div className="hidden w-full md:block md:w-auto" id="navbar-default">
          <MainNavigation></MainNavigation>
        </div>

        {toggleMenu && (
          <div className="md:hidden flex flex-col items-center basis-full">
            <MainNavigation></MainNavigation>
          </div>
        )}
      </div>
    </header>
  );
};

export default HeaderLayout;
